/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import BreadCrumb from '../components/BreadCrumb'
import BlogPostItem from '../components/BlogPostItem'
import Img from 'gatsby-image'

const Blog = ({ data: { blogPage, blogs }, location}) => {

  const { data: blogPageData } = blogPage || {}

  const blogItems = blogs.edges

  const {
    page_title,
    description,
    seo_description
  } = blogPageData

  return (
    <>
      <SEO
        pathname={location.pathname}
        title={page_title}
        desc={seo_description}
      />
      {/* Hero Section */}
      <section className="hero hero-page">
        {blogPageData.top.localFile && <Img fluid={blogPageData.top.localFile.childImageSharp.fluid} className="hero-banner" />}
        {!blogPageData.top.localFile &&
          <div style={{background: "url(/img/staff-banner.jpg)"}} className="hero-banner"></div>
        }
        <div className="container">
          <div className="row">
            <div className="col-xl-10 text-content">
              <BreadCrumb path={[{ label: 'Blog', to: `/blog` }]} />
              <h1>Blog</h1>
              <div className="row" >
                <p className="col-lg-8" dangerouslySetInnerHTML={{ __html: description.html }} />
              </div>
            </div>
          </div>
          <div className="row">
            {blogItems.map((item) => <BlogPostItem key={item.node.id} item={item} gray />)}
          </div>
        </div>
      </section>
      <section className="blog-listings">
        <div className="container">
          <div className="row">

          </div>
        </div>
      </section>
    </>
  )
}

export default Blog

Blog.propTypes = {
  data: PropTypes.shape({
    homepage: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query BlogQuery {
    blogPage: prismicBlogpage {
      data {
        page_title
        top {
            localFile {
                childImageSharp {
                    fluid(maxWidth: 1900, quality: 90) {
                        tracedSVG
                        aspectRatio
                        src
                        srcSet
                        srcWebp
                        srcSetWebp
                        sizes
                        originalImg
                        originalName
                        presentationWidth
                        presentationHeight
                    }
                }
            }
        }
        description {
            html
        }
        seo_description
      }
    }
    blogs: allPrismicBlog(sort: {fields: [first_publication_date], order:DESC}) {
      edges {
          node {
              id
              uid
              data {
                  title
                  description
                  image {
                      localFile {
                          childImageSharp {
                              fluid(maxWidth: 300, quality: 90) {
                                  tracedSVG
                                  aspectRatio
                                  src
                                  srcSet
                                  srcWebp
                                  srcSetWebp
                                  sizes
                                  originalImg
                                  originalName
                                  presentationWidth
                                  presentationHeight
                              }
                          }
                      }
                  }
              }
          }
      }
    }
  }
`
